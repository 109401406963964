h1 {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 72px !important;
  line-height: 130% !important;
  color: #f1f1f1 !important;
  letter-spacing: 0.01em;
  margin-bottom: 0 !important;
}

h2 {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 48px !important;
  line-height: 130% !important;
  color: #f1f1f1 !important;
  letter-spacing: 0.01em;
  margin-bottom: 0 !important;
}

    .strong {
      font-size: inherit !important;
        font-weight: 600 !important;
    }

    .sub_heading {
      padding: 0px 20px;
      border: 1px solid #404040;
      border-radius: 8px;
      display: inline-flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 16px;
      height: 40px;
    }

    .black .sub_heading {
      padding: 0px 20px;
      border: 1px solid #CBCACA;
      border-radius: 8px;
      display: inline-flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 16px;
      height: 40px;
    }
    
    .sub_heading::before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #ff7a00;
    }


/* X-Large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (max-width: 1400px) {
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
  h1 {
    font-size: 62px !important;
  }
  h2 {
    font-size: 41px !important;
    /* width: 952px; */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  h1 {
    font-size: 52px !important;
  }
  h2 {
    font-size: 34px !important;
    /* width: 744px; */
  }
}

@media only screen and (min-width: 541px) and (max-width: 576px) {
  h1 {
    font-size: 42px !important;
  }
  h2 {
    font-size: 27px !important;
    /* width: 536px; */
  }
}

@media only screen and (min-width: 360px) and (max-width: 540px) {
  h1 {
    font-size: 32px !important;
   
  }
  h2 {
    font-size: 20px !important;
    letter-spacing: -0.4px !important;
    /* width: 328px; */
  }
}
