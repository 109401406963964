.culturee_wrraper {
  color: #060606;
}

.culture_heading {
  text-align: center;
  font-size: 163px;
  font-weight: 500;
  margin-top: 160px;
  display: flex;
  justify-content: center;
}

p.culture_heading {
  margin-bottom: 0 !important;
}

.culture_sub {
  font-size: 40px;
  text-align: center;
  color: #060606;
  padding-top: 24px;
  padding-bottom: 208px;
  margin-bottom: 0px;
}

.culture_sub strong {
  font-size: inherit !important;
  font-weight: 500 !important;
}

h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 130%;
  color: #060606;
  letter-spacing: 0.01em;
}

.culture_wrapper {
  color: #060606 !important;
  text-align: center;
  padding: 140px 0px 24px 0px;
  margin-bottom: 0px;
  letter-spacing: -1.44px;
}

.culture_wrapper3 {
  font-size: 20px;
  font-weight: 400;
  color: #4b4b4b;
  padding: 0px 200px 0px;
  text-align: center;
  margin-bottom: 0;
}

/* .image {
  flex: 33.%;
  padding: 0px;
} */
.image_1 {
  margin-right: 16px;
}

.image_3 {
  margin-left: 16px !important;
}

.images_wrapper {
  display: flex;
  justify-content: center;
}

.cul_hr {
  border-bottom: 1px solid #060606;
  margin: 48px 0;
}

.testimonial_wrapperr {
  padding: 140px 0px 48px 0px;
  /* padding-bottom: 64px; */
}

.cul_sub_heading {
  font-size: 16px;
  padding: 12px 20px;
  border: 1px solid #cbcaca;
  border-radius: 8px;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  height: 40px;
  margin-bottom: 16px;
  margin-top: 80px;
}

.cul_sub_heading::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff7a00;
}

.culture_h1 {
  font-weight: 400 !important;
  font-size: 72px !important;
  line-height: 130% !important;
  color: #060606 !important;
  margin-top: 0;
  margin-bottom: 0;
}

.core_value h3 {
  color: #000;
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: 400;
  line-height: 1.2;
  font-size: 32px;
  color: var(--bs-heading-color);
}

.core_value {
  padding-bottom: 140px;
}

.core_font {
  font-size: 18px;
  margin-right: 47px;
  color: var(--primary-light, #4b4b4b);
}

.our_team {
  padding: 80px 0px 42px;
}

.cul_wrapper4 {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 72px !important;
  line-height: 130% !important;
  color: #060606 !important;
  letter-spacing: 0.01em;
  padding-bottom: 48px;
  margin-bottom: 0px !important;
}

.cul_bg {
  padding-top: 80px;
  background-color: #f2e5dc !important;
}

.cul_wrap {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 72px !important;
  line-height: 130% !important;
  color: #060606 !important;
  letter-spacing: 0.01em;
  margin: 0px 0 42px 0;
  position: relative;
  z-index: 10;
}

.cul_main_box .col-lg-4 {
  padding: 0 8px !important;
  margin-bottom: 60px;
}

.cul_main_box {
  width: 866px;
}

.employee_div {
  display: flex;
  justify-content: end;
  margin-top: 40px;
}

.cul_box {
  background-color: #d9d9d9;
  height: 365px;
  display: flex;
  justify-content: center;
  align-items: end;
  margin: 0px 0px 16px 0px;
  position: relative;
}

.line1 {
  border-left: 1px solid #EACBB6;
  height: 458px;
  position: absolute;
  left: 0;
  bottom: 100%;
  z-index: 1 !important;
}

.line2 {
  border-left: 1px solid #EACBB6;
  height: 112px;
  position: absolute;
  left: 0;
  bottom: 100%;
}

.cul_box img {
  height: 318px;
}

.cul_box div {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: #FF7A00;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
  padding: 10.5px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 80px;
}

.cul_box_name {
  color: #060606;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 21px;
}

.box {
  padding-top: 64px;
}

/* -------slider */

:root {
  --color-bg-accent: white;
  --size: clamp(10rem, calc(1rem + 40vmin), 30rem);
  --gap: calc(var(--size) / 16);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}

* {
  box-sizing: border-box;
}

.marquee {
  display: flex;
  overflow: hidden !important;
  user-select: none;
  gap: var(--gap);
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  animation: scroll-x var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee--vertical {
  --mask-direction: to bottom;
}

.marquee--vertical,
.marquee--vertical .marquee__group {
  flex-direction: column;
}

.marquee--vertical .marquee__group {
  animation-name: scroll-y;
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }

  to {
    transform: translateX(var(--scroll-end));
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(var(--scroll-start));
  }

  to {
    transform: translateY(var(--scroll-end));
  }
}

/* Element styles */
.marquee span {
  display: grid;
  place-items: center;
  background: var(--color-bg-accent);
  padding: calc(var(--size) / 10);
  border-radius: 0.5rem;
  width: 278px;
  height: 159px;
}

.marquee--vertical span {
  padding: calc(var(--size) / 6);
}

/* Parent wrapper */
.artical {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: auto;
  max-width: 100vw;
  padding-top: 0px;
}

.wrapper--vertical {
  flex-direction: row;
  height: 100vh;
}

/* ------responsive */

@media (max-width: 280px) {
  .culture_heading {
    font-size: 36px !important;
  }

  .culture_sub {
    font-size: 17px !important;
  }

  .cul_main_box {
    width: 100%;
  }

  .employee_div {
    display: flex;
    justify-content: center;
  }

  .line1 {
    border-left: 0;
  }

  .line2 {
    border-left: 0;
  }
}

@media (max-width: 412px) {
  .culture_heading {
    font-size: 30px;
  }

  .line1 {
    border-left: 0;
  }

  .line2 {
    border-left: 0;
  }
}

@media (max-width: 1024px) {
  .culture_heading {
    font-size: 132px !important;
  }

  .image_1 {
    margin-right: 0px;
    padding-right: 16px;
  }

  .image_3 {
    padding-left: 16px;
    margin-left: 0px !important;
  }

  .core_value h3 {
    font-size: 29px;
  }

  .cul_main_box {
    width: 100%;
  }

  .employee_div {
    display: flex;
  }

  .line1 {
    border-left: 0;
  }

  .line2 {
    border-left: 0;
  }
}

@media (max-width: 1280px) {
  .cul_wrap {
    padding-bottom: 42px;
  }

  .line1 {
    border-left: 0;
  }

  .line2 {
    border-left: 0;
  }
}

@media (max-width: 992px) {
  .culture_heading {
    font-size: 104px !important;
  }

  .culture_sub {
    font-size: 30px;
    font-weight: 400;
    padding-bottom: 190px;
  }

  .culture_wrapper {
    font-size: 55px !important;
    padding: 60px 14px 12px;
    letter-spacing: -0.48px;
  }

  .culture_wrapper3 {
    font-size: 18px;
    font-weight: 400;
    padding: 0px;
  }

  .cul_sub_heading {
    padding: 19 12px;
    font-size: 15px;
  }

  .cul_sub_heading::before {
    width: 8px;
    height: 8px;
  }

  .core_value h3 {
    font-size: 29px;
  }

  .culture_h1 {
    font-size: 60px !important;
  }

  .image_1 {
    margin: 0 !important;
    padding-right: 16px;
  }

  .image_3 {
    padding-left: 16px;
    margin-left: 0 !important;
  }

  .testimonial_wrapperr {
    padding: 140px 0px 48px 0px;
  }

  .cul_wrapper4 {
    font-size: 60px !important;
    margin-top: 17px;
  }

  .marquee span {
    width: 240px;
    height: 133px;
  }

  .marquee__group {
    gap: 14px;
    grid-gap: 14px;
  }

  .cul_wrap {
    font-size: 60px !important;
    margin: 18px 0 38px 0;
  }

  .line1 {
    border-left: 0;
  }

  .line2 {
    border-left: 0;
  }
}

@media (max-width: 768px) {
  .culture_heading {
    font-size: 85px !important;
  }

  .culture_sub {
    font-size: 23px;
    font-weight: 400;
    /* padding-bottom: 150px; */
  }

  .culture_wrapper {
    font-size: 42px !important;
    /* padding: 0 14px; */
    font-weight: 400;
  }

  .culture_wrapper3 {
    font-size: 17px;
    font-weight: 400;
    padding: 0px 0px 0px;
  }

  .core_value h3 {
    margin-bottom: 12px;
  }

  .core_value {
    padding-bottom: 100px;
  }

  .core_font {
    margin-bottom: 34px;
  }

  .cul_sub_heading {
    padding: 18 12px;
    font-size: 14px;
  }

  .cul_sub_heading::before {
    width: 8px;
    height: 8px;
  }

  .image_3 {
    margin-left: 0;
  }

  .img-fluid {
    max-width: 100%;
    height: 100% !important;
  }

  .culture_h1 {
    font-size: 49px !important;
  }

  .testimonial_wrapperr {
    padding: 100px 0px 48px 0px;
  }

  .cul_wrapper4 {
    font-size: 49px !important;
    margin-top: 14px;
  }

  .marquee span {
    width: 195px;
    height: 103px;
  }

  .marquee__group {
    gap: 14px;
    grid-gap: 14px;
  }

  .cul_wrap {
    font-size: 49px !important;
    margin: 15px 0 30px 0;
  }

  .cul_main_box {
    width: 100%;
  }

  .employee_div {
    display: block;
  }

  .cul_box img {
    height: 300px;
  }

  .line1 {
    border-left: 0;
  }

  .line2 {
    border-left: 0;
  }
}

@media (max-width: 576px) {
  .culture_heading {
    font-size: 70px !important;
  }

  .culture_sub {
    font-size: 21px;
    font-weight: 400;
    padding: 0px 0px 132px;
  }

  .culture_wrapper {
    font-size: 30px !important;
    padding: 60px 0px 12px;
    font-weight: 400;
    letter-spacing: -0.28px;
  }

  .cul_box {
    margin-bottom: 12px !important;
    margin: 0 6px;
  }

  .culture_wrapper3 {
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.28px;
  }

  .core_value h3 {
    margin-bottom: 12px;
  }

  .core_value {
    padding-bottom: 60px;
  }

  .cul_sub_heading {
    padding: 16 11px;
    font-size: 14px;
  }

  .black .sub_heading {
    font-size: 12px;
    padding: 12px 12px;
    margin-bottom: 12px;
    height: 28px;
  }

  .cul_sub_heading::before {
    width: 7px;
    height: 7px;
  }

  .culture_h1 {
    font-size: 36px !important;
  }

  .testimonial_wrapperr {
    padding: 60px 0px 32px 0px;
  }

  .image img {
    height: 100%;
  }

  .image_1 {
    padding-right: 12px !important;
    margin: 0;
  }

  .image_3 {
    margin: 0;
    padding-left: 12px;
  }

  .core_font {
    font-size: 18px;
    margin-right: 128px;
    margin-bottom: 32px;
  }

  .cul_wrapper4 {
    font-size: 36px !important;
    margin-top: 13px;
  }

  .marquee span {
    width: 143px;
    height: 83px;
  }

  .marquee__group {
    gap: 13px;
    grid-gap: 13px;
  }

  .cul_wrap {
    font-size: 36px !important;
    margin: 20px 0 25px 0;
  }

  .employee_div {
    padding: 0 !important;
  }

  .cul_main_box {
    width: 550px;
    margin-right: 0 !important;
  }

  .cul_main_box .col-6 {
    width: 270px;
    padding: 0px 0px !important;
  }

  .line1 {
    border-left: 0;
  }

  .line2 {
    border-left: 0;
  }
}

@media only screen and (min-width: 360px) and (max-width: 575px) {
  .black .sub_heading {
    font-size: 12px;
    padding: 12px 12px;
    margin-bottom: 12px;
    height: 28px;
  }

  .black .sub_heading::before {
    width: 6px;
    height: 6px;
  }

  .culture_heading {
    font-size: 46px !important;
  }

  .core_value h3 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 12px;
    line-height: 130%;
    letter-spacing: -0.4px;
  }

  .core_value {
    padding-bottom: 28px;
  }

  .core_font {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.28px;
    margin: 0px 0px 32px;
  }

  .culture_sub {
    font-size: 16px;
    font-weight: 400;
    padding: 12px 0px 132px 0px;
    margin-bottom: 0px;
  }

  .culture_wrapper {
    font-size: 24px !important;
    padding-top: 60px;
    font-weight: 400;
    padding-bottom: 12px;
  }

  .culture_wrapper3 {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.28px;
  }

  .image {
    height: 118px !important;
  }

  .image img {
    height: 100%;
  }

  .image_1 {
    padding-right: 8px !important;
  }

  .image_3 {
    padding-left: 8px;
  }

  .cul_hr {
    margin: 32px 0px 32px;
  }


  .cul_sub_heading {
    padding: 12px;
    font-size: 12px;
  }

  .cul_sub_heading::before {
    width: 6px;
    height: 6px;
  }

  .culture_h1 {
    font-size: 24px !important;
    margin-top: 0px;
  }

  .testimonial_wrapper .container {
    padding: 0px;
    padding-bottom: 0px;
  }

  .testimonial_wrapperr {
    padding: 60px 0px 32px 0px;
  }

  .cul_wrapper4 {
    font-size: 24px !important;
    margin-top: 12px;
    padding-bottom: 24px;
  }

  .cul_bg {
    padding-top: 40px;
  }

  .testimonial_wrapper h1 {
    font-size: 24px !important;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.48px;
    margin: 0px 0px 24px;
  }

  .box {
    padding-top: 34px;
  }

  .marquee span {
    width: 104px;
    height: 60px;
  }

  .marquee__group {
    gap: 12px;
    grid-gap: 12px;
  }

  .marquee--reverse .marquee__group {
    animation-direction: reverse;
    animation-delay: -3s;
  }

  .cul_wrap {
    font-size: 24px !important;
    margin: 10px 0 14px 0;
    padding-bottom: 24px;
  }

  .testimonial_wrapper {
    padding-bottom: 60px;
  }

  .cul_box .container {
    padding: 0 !important;
  }

  .cul_main_box .col-6 {
    padding: 0px 6px !important;
  }



  .employee_div {
    display: flex;
    margin-top: 0;
  }

  .cul_main_box {
    width: 100%;
    justify-content: center;
    /* margin-right: 0 !important ; */
    /* margin-left: 10px !important; */
  }

  .cul_main_box .col-sm-6 {
    width: 170px;
    margin-bottom: 40px;
  }

  .cul_box {
    height: 207px;
    margin: 0px 0px 12px 0px;
  }

  .cul_box img {
    height: 185px;
  }

  .cul_box_name {
    font-size: 16px;
  }

  .cul_box div {
    font-size: 10px;
    padding: 8px 10px;
    left: 5px;
    bottom: 5px;
  }

  .line1 {
    border-left: 0;
  }

  .line2 {
    border-left: 0;
  }
}