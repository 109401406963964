.home_wrapper {
  overflow: hidden;
  position: relative;
}

.main_wrapper {
  padding-top: 304px;
  padding-bottom: 140px;
}

.main_wrapper h1 {
  margin-top: 24px;
}

.main_wrapper img {
  height: 80px;
  width: 80px;
}

.about_wrapper {
  padding: 140px 0px;
  text-align: center;
}

.about_wrapper button {
  margin: auto;
  margin-top: 40px;
}

/* PROJECT_WRAPPER >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.project_wrapper {
  padding-bottom: 160px 0;
}

.project_wrapper .project_detail {
  margin-top: 40px;
}

.project_card {
  position: relative;
  border-radius: 39px !important;
  margin-bottom: 16px;
  perspective: 1000px;
  overflow: hidden;
  background-size: cover;
  will-change: transform;
  transition: transform 0.2s ease;
  backface-visibility: hidden;
  cursor: pointer;
  /* height: 651px; */
  background-color: transparent !important;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  transition: all ease 0.2s;
}

.project_card .card_background {
  background-color: transparent !important;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  transition: all ease 0.2s;
}

.project_card:hover {
  box-shadow: none;
  border-radius: 39px;
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}

.card_image {
  width: 100%;
  height: auto;
  border-radius: 39px;
  transition: transform 0.35s ease-in-out;
  overflow: hidden;
}

.card_content {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
}

.card_icon {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: end;
  top: 0;
  padding: 40px;
  opacity: 0;
  transition: 0.5s;
}

.card_icon img {
  width: 72px;
  height: 72px;
}

.card:hover .card_icon {
  opacity: 1;
}

.card_detail {
  box-sizing: border-box;
  width: 100%;
  padding: 45px 32px;
  height: auto;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
}

.project_logo {
  background-color: #f1f1f1;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  padding: 12px 0px;
}

.project_heading {
  background-color: #f1f1f1;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 30px;
  border-radius: 50px;
  color: #060606;
}

.project_name p {
  font-size: 24px;
  margin-bottom: 0 !important;
  color: #f1f1f1;
}

.project_wrapper button {
  margin: auto;
  margin-top: 40px;
}

.culture_navigate{
  text-decoration: none;
}

/* EXPERTISE-WRAPPER >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.expertise_wrapper {
  padding: 140px 0;
}

.expertise_wrapper h1 {
  margin-bottom: 56px !important;
}

.exp {
  display: flex;
  justify-content: space-between;
}
.exp_flex {
  display: flex;
  justify-content: space-between;
}

.exp_title p {
  font-size: 18px;
  color: #ababab;
  margin-bottom: 0px;
}

.exp_des p {
  font-size: 24px;
  color: #f1f1f1;
  margin-bottom: 24px;
}

.exp_tag {
  display: inline-block;
}

.exp_tag .tag-pill {
  background-color: #404040;
  border-radius: 30px;
  display: inline-block;
  padding: 8px 20px;
  font-size: 16px;
  margin: 0px 12px 12px 0px;
}

.divider {
  margin: 1rem 0 !important;
  color: inherit !important;
  border: 0 !important;
  border-top: 1px solid #f1f1f1 !important;
}

/* technologies-WRAPPER >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.techno {
  padding-top: 48px;
  padding-bottom: 140px !important;
}

.rainbowGradient {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 36px;
  box-shadow: inset 0 0 26px 25px #060606, inset 0 0 92px 0px #060606;
  background: linear-gradient(to right, #696eff, #f8acff);
}

.techno_wrapper {
  margin: auto;
  overflow: hidden;
  background: #060606;
  border-radius: 32px;
  position: relative;
  box-shadow: inset 53px 0px 90px #060606, inset -53px 0px 90px #060606;
  z-index: 1;
}

.techno_wrapper ul {
  height: 192px;
  justify-content: center;
  align-items: center;
  display: flex;
  animation: cambio 10s infinite linear;
  margin: 0;
}

.techno_wrapper li {
  padding: 0 25px;
  list-style: none;
}

.techno_wrapper img {
  max-width: fit-content !important;
  height: auto !important;
}

@keyframes cambio {
  from {
    margin-left: 0%;
  }
  to {
    margin-left: -100%;
  }
}

/* Slider  */
.testimonial_wrapper {
  padding-bottom: 140px;
}

.slide_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
}

.slide_button span {
  cursor: pointer;
}

.testimonial_slider .card {
  padding: 48px;
  background-color: #171717 !important;
  border-radius: 40px;
  margin: 0px 10px;
}

.customer_review p {
  font-size: 24px;
  margin-bottom: 48px !important;
  color: #f1f1f1;
}

.customer_name p {
  font-size: 24px;
  margin-bottom: 4px !important;
  color: #f1f1f1;
}

.customer_name span {
  font-size: 16px;
  color: #f1f1f1;
}

.slick-slide img {
  display: inline-block !important;
}

/*  Responsive CSS  */

@media (max-width: 992px) {
  .main_wrapper {
    padding-top: 294px;
    padding-bottom: 120px;
  }
  .main_wrapper img {
    height: 72px;
    width: 72px;
  }
  .about_wrapper {
    padding: 135px 0px;
  }

  .expertise_wrapper {
    padding: 135px 0px;
  }

  .expertise_wrapper h1 {
    font-size: 60px !important;
  }
  .technologies_wrapper h1 {
    font-size: 60px !important;
  }
  .testimonial_wrapper h1 {
    font-size: 60px !important;
  }

  .techno {
    padding-top: 42px;
    padding-bottom: 120px !important;
  }
  .card_icon img {
    width: 45px;
  }
  .exp {
    /* padding-top: 42px !important; */
    padding-bottom: 114px;
  }
  .exp_icon img {
    width: 106px !important;
    height: 106px !important;
  }
  .exp_title p {
    font-size: 17px !important;
    padding-left: 32px;
  }
  .exp_des p {
    font-size: 22px;
  }
  .exp_flex {
    display: flex;
    /* justify-content: space-between; */
  }
  .tag-pill {
    font-size: 17px !important;
  }
  .slide_button span svg {
    width: 60px !important;
    height: 60px !important;
    margin: 0 3px;
  }
  .expertise_section {
    padding-top: 36px;
  }
  .testimonial_wrapper {
    padding-bottom: 120px;
  }
}

@media (max-width: 768px) {
  .main_wrapper {
    padding-top: 306px;
    padding-bottom: 100px;
  }
  .main_wrapper img {
    height: 64px;
    width: 64px;
  }
  .about_wrapper {
    padding: 110px 0px;
  }

  .expertise_wrapper {
    padding: 110px 0px;
  }

  .expertise_wrapper h1 {
    font-size: 48px !important;
  }
  .technologies_wrapper h1 {
    font-size: 48px !important;
  }
  .testimonial_wrapper h1 {
    font-size: 48px !important;
  }

  .techno {
    padding-top: 36px;
    padding-bottom: 100px !important;
  }
  .exp {
    /* padding-top: 36px !important; */
    padding-bottom: 96px;
  }
  .card_icon img {
    width: 40px;
  }
  .exp_icon img {
    width: 94px !important;
    height: 94px !important;
  }
  .exp_title p {
    font-size: 15px !important;
    padding-left: 22px;
  }
  .exp_des p {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .exp_flex {
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .tag-pill {
    font-size: 17px !important;
  }
  .slide_button span svg {
    width: 48px !important;
    height: 48px !important;
    margin: 0 3px;
  }
  .project_logo {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
  }
  .project_logo img {
    width: 30px;
  }
  .project_heading {
    font-size: 13px;
    padding: 0px 17px;
  }
  .project_name p {
    font-size: 17px;
  }

  .testimonial_wrapper {
    padding-bottom: 100px;
  }
}

@media (max-width: 576px) {
  .main_wrapper {
    padding-top: 318px;
    padding-bottom: 80px;
  }
  .main_wrapper img {
    height: 56px;
    width: 56px;
  }
  .about_wrapper {
    padding: 90px 0px;
  }

  .expertise_wrapper {
    padding: 90px 0px;
  }

  .expertise_wrapper h1 {
    font-size: 36px !important;
    margin-bottom: 24px;
  }
  .technologies_wrapper h1 {
    font-size: 36px !important;
  }
  .testimonial_wrapper h1 {
    font-size: 36px !important;
  }
  .card_icon img {
    width: 35px;
  }
  .exp {
    /* padding-top: 30px !important; */
    padding-bottom: 0px;
  }
  .expertise_section {
    padding-top: 0px;
  }
  .exp_icon img {
    width: 82px !important;
    height: 82px !important;
  }
  .exp_title p {
    font-size: 14px !important;
    padding-left: 20px;
  }
  .techno {
    padding-top: 30px;
    padding-bottom: 80px !important;
  }
  .exp_des p {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .exp_flex {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 13px !important;
  }
  .tag-pill {
    font-size: 15px !important;
    padding: 8px 20px;
  }
  .techno_wrapper ul {
    height: 140px !important;
  }
  .techno_wrapper li {
    width: 120px;
    height: 60px;
  }
  .techno_wrapper img {
    width: 58px;
    height: 58px;
  }
  .slide_button span svg {
    width: 36px !important;
    height: 36px !important;
    margin: 0 3px;
  }
  .testimonial_wrapper {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 540px) {
  .sub_heading {
    font-size: 12px;
    padding: 0px 12px;
    margin-bottom: 12px;
    height: 28px;
  }

  .sub_heading::before {
    width: 6px;
    height: 6px;
  }

  .main_wrapper {
    padding-top: 347px;
    padding-bottom: 60px;
  }
  .main_wrapper h1 {
    letter-spacing: -0.64px !important;
  }
  .main_wrapper img {
    height: 48px;
    width: 48px;
  }
  .about_wrapper {
    padding: 60px 0;
  }
  .expertise_wrapper {
    padding: 60px 0px;
  }
  .project_wrapper {
    padding-bottom: 60px;
  }
  .expertise_wrapper h1 {
    font-size: 24px !important;
    margin-bottom: 24px;
  }
  .technologies_wrapper h1 {
    font-size: 24px !important;
  }

  .testimonial_wrapper {
    padding-bottom: 60px;
  }

  .testimonial_wrapper h1 {
    font-size: 24px !important;
  }

  .project_logo {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center !important;
    padding: 10px 0px;
  }
  .project_logo_img {
    width: 24px;
    height: 19px;
  }
  .project_heading {
    font-size: 14px;
    padding: 0px 20px;
  }
  .project_name p {
    font-size: 14px;
  }
  .card_icon img {
    width: 30px;
  }
  .sub_heading h1 {
    font-size: 24px !important;
  }
  .exp {
    padding-bottom: 0px;
  }
  .expertise_section {
    padding-top: 0px;
  }
  .exp_icon img {
    width: 70px !important;
    height: 70px !important;
  }
  .exp_title p {
    font-size: 16px !important;
    padding-left: 16px;
  }
  .exp_des p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .exp_flex {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 12px !important;
  }
  .tag-pill {
    font-size: 14px !important;
    padding: 8px 20px;
  }
  .techno {
    padding-top: 24px;
    padding-bottom: 60px !important;
  }
  .techno_wrapper ul {
    height: 88px !important;
  }
  .techno_wrapper li {
    width: 77px;
    height: 40px;
  }
  .techno_wrapper img {
    width: 40px;
  }
  .customer_review p {
    font-size: 16px;
    margin-bottom: 25px !important;
    font-weight: 400 !important;
  }
  .testimonial_slider .card {
    padding: 24px;
    margin: 0px 2px;
  }
  .slick-track {
    height: 320px;
  }
  .customer-profile {
    width: 48px;
    height: 48px;
  }
  .customer_name p {
    font-size: 16px;
  }
  .customer_name span {
    font-size: 12px;
  }
  .slide_button span svg {
    width: 24px !important;
    height: 24px !important;
    margin: 0 3px;
  }
  .slide_button h1 {
    font-size: 24px;
  }
  .slide_button {
    padding-bottom: 24px !important;
  }
  .slick-track {
    height: 294px;
  }
  .card .d-flex .project_logo img {
    width: 20px;
    height: 20px;
  }

  .about_wrapper button {
    margin: auto;
    margin-top: 24px;
  }

  .project_wrapper h1 {
    font-size: 24px !important;
  }

  .project_wrapper .project_detail {
    margin-top: 24px;
  }

  .card_detail {
    padding: 24px 16px;
  }

  .project_wrapper button {
    margin-top: 0px;
  }

  .rainbowGradient {
    padding: 2px;
    border-radius: 20px;
    box-shadow: inset 0 0 60px 25px #060606, inset 0 0 92px 0px #060606;
  }
  .techno_wrapper {
    border-radius: 20px;
  }

  .exp_tag_div {
    width: 100%;
    overflow-x: scroll !important;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .exp_tag {
    width: 609px;
    display: flex;
    flex-wrap: wrap;
  }

  .tag-pill {
    font-size: 14px;
  }

  .exp_tag_div::-webkit-scrollbar {
    display: none;
  }
}
