.black .btn_navbar .btn_arrow .icon_svg {
  transition: 0.3s ease-in;
  stroke: #f1f1f1;
}
.btn_navbar .btn_arrow .icon_svg {
  transition: 0.3s ease-in;
  stroke: #060606;
}

.black .btn_navbar {
  display: flex;
  background-color: #060606;
  border: 1px solid transparent;
  border-radius: 50px;
  padding: 10px 20px;
  gap: 5px;
  transition: all 0.5s ease-out;
  background: linear-gradient(to right, #ffffff 50%, #000000 50%);
  transition: all 0.5s ease-out;
  background-size: 200% 100%;
  background-position: right bottom;
  color: #f1f1f1;
  font-weight: 400;
}
.btn_navbar {
  display: flex;
  background-color: #f1f1f1;
  border: 1px solid transparent;
  border-radius: 50px;
  padding: 10px 20px;
  gap: 5px;
  /* transition: all .5s ease-out; */
  /* background: linear-gradient(to right, #000000 50%, #ffffff 50%); */
  transition: all 0.5s ease-out;
  background-size: 200% 100%;
  background-position: right bottom;
  color: #060606;
  font-weight: 400;
}

.black .btn_navbar:hover,
.black .btn_navbar:focus {
  background-color: transparent;
  color: #060606;
  /* border: 1px solid transparent; */
  /* box-shadow: inset 180px 0 0 0 red; */
  /* background: linear-gradient(45deg,#FF0055,#F94CFF); */
  background-position: left bottom;
  font-weight: 500;
}

.btn_navbar:hover,
.btn_navbar:focus {
  background-color: #f1f1f1;
  color: #0b0c0b;
  /* border: 1px solid transparent; */
  /* box-shadow: inset 180px 0 0 0 red; */
  /* background: linear-gradient(45deg,#FF0055,#F94CFF); */
  /* background-position: left bottom; */
  font-weight: 500;
}

.black .btn_navbar:hover .icon_svg {
  stroke: #060606;
}

.btn_navbar:hover .icon_svg {
  stroke: #f1f1f1;
}

/* Main Button */

.black .main_btn .btn_arrow .icon_svg {
  transition: 0.5s ease-in;
  stroke: #060606;
}

.main_btn .btn_arrow .icon_svg {
  transition: 0.5s ease-in;
  stroke: #ffffff;
}

.black .main_btn {
  display: flex;
  background-color: transparent;
  border: 1px solid #787879;
  border-radius: 50px;
  padding: 0px 32px;
  color: #f1f1f1;
  gap: 8px;
  transition: all 0.5s ease-out;
  background: linear-gradient(to right, #060606 50%, #F1F1F1 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_btn {
  display: flex;
  background-color: transparent;
  border: 1px solid #787879;
  border-radius: 50px;
  padding: 0px 32px;
  color: #f1f1f1;
  gap: 8px;
  transition: all 0.5s ease-out;
  background: linear-gradient(to right, #F1F1F1 50%, #060606 50%);
  background-size: 205% 100%;
  background-position: right bottom;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.black .main_btn:hover,
.black .main_btn:focus {
  background-color: transparent;
  color: #F1F1F1;
  background-position: left bottom;
}

.main_btn:hover,
.main_btn:focus {
  background-color: transparent;
  color: #060606;
  background-position: left bottom;
}

.black .main_btn:hover .icon_svg {
  stroke: #F1F1F1;
  transition: 0.2s ease-in;
}

.main_btn:hover .icon_svg {
  stroke: #060606;
  transition: 0.2s ease-in;
}

.black .main_btn:hover .btn_arrow {
  transform: rotate(45deg);
  transition: 0.2s ease-in;
}

.main_btn:hover .btn_arrow {
  transform: rotate(45deg);
  transition: 0.2s ease-in;
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .main_btn {
    height: 56px;
  }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
  .main_btn {
    padding: 0px 30px;
    height: 54px;
  }
}

@media only screen and (min-width: 541px) and (max-width: 576px) {
  .main_btn {
    padding: 0px 20px;
    height: 44px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 540px) {
  .main_btn {
    padding: 0px 16px;
    height: 40px;
  }
}
