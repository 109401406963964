.black a.talk_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 120px; */
  color: #060606;
  text-decoration: none;
  transition: 0.3s ease-in;
}
a.talk_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 120px; */
  color: #f1f1f1;
  text-decoration: none;
  transition: 0.3s ease-in;
}

a.talk_section .big_font {
  font-size: 240px;
  line-height: 230px;
}

.black a.talk_section .big_arrow {
  stroke: #060606;
}

a.talk_section .big_arrow {
  stroke: #f1f1f1;
  transition: 0.3s ease-in;
}

a.talk_section:hover .big_arrow {
  transform: rotate(45deg);
  transition: 0.3s ease-in;
}

.black a.social_box {
  border: 1px solid #cbcaca;
  padding: 24px 32px;
  border-radius: 24px;
  /* transition: 0.1s ease-in; */
  display: block;
  text-decoration: none;
  -webkit-transition: border-color 1s ease;
  -moz-transition: border-color 1s ease;
  -o-transition: border-color 1s ease;
  -ms-transition: border-color 1s ease;
  transition: border-color 1s ease;
}

a.social_box {
  border: 1px solid #404040;
  padding: 24px 32px;
  border-radius: 24px;
  /* transition: 0.1s ease-in; */
  display: block;
  text-decoration: none;
  -webkit-transition: border-color 1s ease;
  -moz-transition: border-color 1s ease;
  -o-transition: border-color 1s ease;
  -ms-transition: border-color 1s ease;
  transition: border-color 1s ease;
}

/* .black .social_icon {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
} */

.social_icon {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
  /* transition: 0.2s ease-in; */
  -webkit-transition: 0.6s ease;
  -moz-transition: 0.6s ease;
  -o-transition: 0.6s ease;
  -ms-transition: 0.6s ease;
  transition: 0.6s ease;
}

.social_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.black .social_detail p {
  font-size: 20px;
  margin-bottom: 0 !important;
  color: #060606;
}

.social_detail p {
  font-size: 20px;
  margin-bottom: 0 !important;
  color: #f1f1f1;
}

.black .social_detail svg {
  height: 28px;
  width: 28px;
  transition: 0.2s ease-in;
  stroke: #060606;
}

.social_detail svg {
  height: 28px;
  width: 28px;
  transition: 0.2s ease-in;
  stroke: #f1f1f1;
}

.black .social_box:hover {
  border: 1px solid #060606;
  transition: 0.2s ease-in;
}

.social_box:hover {
  border: 1px solid #f1f1f1;
  transition: 0.2s ease-in;
}

.black .social_box:hover .social_detail svg {
  transform: rotate(45deg);
  stroke: #060606;
  transition: 0.2s ease-in;
}

.social_box:hover .social_detail svg {
  transform: rotate(45deg);
  stroke: #f1f1f1;
  transition: 0.2s ease-in;
}

.black .social_box:hover .social_icon {
  background-color: #060606;
  /* transition: 0.2s ease-in; */
  -webkit-transition: background-color 1s ease;
  -moz-transition: background-color 1s ease;
  -o-transition: background-color 1s ease;
  -ms-transition: background-color 1s ease;
  transition: background-color 1s ease;
}

.social_box:hover .social_icon {
  background-color: #f1f1f1;
  /* transition: 0.2s ease-in; */
  -webkit-transition: background-color 1s ease;
  -moz-transition: background-color 1s ease;
  -o-transition: background-color 1s ease;
  -ms-transition: background-color 1s ease;
  transition: background-color 1s ease;
}

.black .social_box .icon_svg {
  fill: #060606;
}

.social_box .icon_svg {
  fill: #f1f1f1;
}

.black .social_box:hover .icon_svg {
  fill: #f1f1f1;
}

.social_box:hover .icon_svg {
  fill: #060606;
}

.footer_bottom {
  display: flex;
  justify-content: space-between;
  padding: 40px 0px;
}

.black .footer_bottom p {
  font-size: 14px;
  color: #404040;
  margin-bottom: 0px !important;
}

.footer_bottom p {
  font-size: 14px;
  color: #787879;
  margin-bottom: 0px !important;
}

.black .footer_bottom a {
  text-decoration: none !important;
  color: #404040 !important;
}

.footer_bottom a {
  text-decoration: none !important;
  color: #787879 !important;
}

.black .footer_bottom a:hover {
  text-decoration: none;
  color: #060606 !important;
}

.footer_bottom a:hover {
  text-decoration: none;
  color: #f1f1f1 !important;
}

.social_wrapper {
  padding-top: 60px;
}

/* ------responsive */

@media only screen and (min-width: 993px) and (max-width: 1400px) {
  a.talk_section .big_font {
    font-size: 170px;
  }
  /* a.talk_section {
    height: 300px;
    padding-top: 110px;
    padding-bottom: 52px;
  } */
  .big_arrow {
    width: 195px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  a.talk_section .big_font {
    font-size: 130px;
    line-height: 129px;
  }
  /* a.talk_section {
    height: 300px;
    padding-top: 110px;
    padding-bottom: 52px;
  } */
  .big_arrow {
    width: 85px !important;
  }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
  a.talk_section .big_font {
    font-size: 130px;
    line-height: 130px;
  }
  /* a.talk_section {
    height: 260px;
    padding-top: 90px;
    padding-bottom: 45px;
  } */
  .big_arrow {
    width: 105px !important;
  }
}

@media (max-width: 576px) {
  a.talk_section .big_font {
    font-size: 90px;
    line-height: 90px;
  }
  /* a.talk_section {
    height: 190px;
    padding-top: 90px;
    padding-bottom: 40px;
  } */
  .big_arrow {
    width: 80px !important;
    height: 80px;
  }
  .social {
    margin-bottom: 16px;
  }
  .social_box {
    margin-bottom: 18px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 540px) {
  .social_box {
    margin-bottom: 16px;
  }
  /* a.talk_section {
    padding-top: 60px !important;
    height: 165px;
    padding-bottom: 32px !important;
  } */
  a.talk_section .big_font {
    font-size: 65px;
    letter-spacing: -3.25px;
  }
  .big_arrow {
    width: 55px !important;
    height: 55px;
  }
  .footer_bottom {
    display: inline-block;
    padding: 16px 0px 16px;
  }
  .footer_bottom p {
    margin-bottom: 8px !important;
    font-size: 12px !important;
  }
  .social_wrapper {
    padding-top: 32px;
  }
  .footer_bottom a {
    font-size: 12px !important;
  }
  .black .footer_bottom a {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 280px) and (max-width: 359px) {
  .social_wrapper {
    padding-top: 32px;
  }
  /* a.talk_section {
    padding-top: 60px !important;
    height: 165px;
    padding-bottom: 32px !important;
  } */
  a.talk_section .big_font {
    font-size: 45px !important;
    /* letter-spacing: -3.25px; */
  }
  .big_arrow {
    width: 40px !important;
    height: 40px !important;
  }
  .footer_bottom {
    display: inline-block;
    padding: 16px 0px 16px;
  }
  .footer_bottom p,
  .footer_bottom p a {
    margin-bottom: 8px !important;
    font-size: 12px !important;
  }
  .footer_bottom a {
    font-size: 12px !important;
  }
  .black .footer_bottom a {
    font-size: 12px !important;
  }
}
