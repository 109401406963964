.loader-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	z-index: 9999;
	display: grid;
	display: flex;
	justify-content: center;
}

.loader-container .BigArrow {
	position: absolute;
	right: 20px;
	width: 110px;
	height: 110px;
	top: 20px;
}

.loader-container .gif {
	text-align: center;
	width: 100%;
}

.loader {
	display: flex;
	justify-content: center;
	position: absolute;
	background-color: transparent;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	animation: loaderAnimation 4s ease-in-out;
}

@keyframes loaderAnimation {
	0% {
		top: 0%;
	}

	50% {
		top: 55%;
	}

	75% {
		top: 55%;
	}

	90% {
		top: 0%;
	}
}

.loader h1 {
	top: -160px;
	position: relative;
	text-align: center;
	display: flex;
	justify-content: center;
	font-size: 128px !important;
	bottom: 0;
}

.loader h1:after {
	content: '';
	display: block;
	/* top: 90px; */
	right: 0;
	width: 24px;
	height: 24px;
	background-color: #FF7A00;
	box-shadow: 0px 0px 10px 5px #FF7A00;
	;
	border-radius: 50%;
	animation: loaderPointAnimation 3.5s;
	/* left: 62%;
    top: -30%; */
	position: absolute;
}

@keyframes loaderPointAnimation {
	0% {
		background-color: #000000;
		box-shadow: 0 0 50px #000000;
	}

	16% {
		background-color: #000000;
		box-shadow: 0 0 50px #000000;
	}

	32% {
		background-color: #000000;
		box-shadow: 0 0 50px #000000;
	}

	48% {
		background-color: #000000;
		box-shadow: 0 0 50px #000000;
	}

	70% {
		background-color: #000000;
		box-shadow: 0 0 50px #000000;
	}

	100% {
		background-color: #FF7A00;
		box-shadow: 0px 0px 10px 5px #FF7A00;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.loader h1 {
		font-size: 100px !important;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.loader {
		display: flex;
		justify-content: center;
		position: absolute;
		background-color: transparent;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		animation: loaderAnimation 5s ease-in-out;
	}

	@keyframes loaderAnimation {
		0% {
			top: 0%;
		}

		50% {
			top: 65%;
		}

		55% {
			top: 65%;
		}

		100% {
			top: 0%;
		}
	}

	.loader h1 {
		top: -150px;
		position: relative;
		text-align: center;
		display: flex;
		justify-content: center;
		font-size: 80px !important;
		bottom: 0;
	}

	.loader-container .BigArrow {
		position: absolute;
		right: 20px;
		width: 80px;
		height: 80px;
		top: 20px;
	}

	.loader h1:after {
		width: 16px;
		height: 16px;
	}


}

@media only screen and (min-width: 360px) and (max-width: 575px) {
	.loader {
		display: flex;
		justify-content: center;
		position: absolute;
		background-color: transparent;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		animation: loaderAnimation 5s ease-in-out;
	}

	@keyframes loaderAnimation {
		0% {
			top: 0%;
		}

		50% {
			top: 70%;
		}

		55% {
			top: 70%;
		}

		100% {
			top: 0%;
		}
	}

	.loader h1 {
		top: -150px;
		position: relative;
		text-align: center;
		display: flex;
		justify-content: center;
		font-size: 50px !important;
		bottom: 0;
	}

	.loader h1:after {
		content: '';
		display: block;
		top: -10px;
		right: 0;
		width: 16px;
		height: 16px;
		background-color: #FF7A00;
		box-shadow: 0px 0px 10px 5px #FF7A00;
		;
		border-radius: 50%;
		animation: loaderPointAnimation 3.5s;
		/* left: 62%; */
		/* top: 0%; */
		position: absolute;
	}

	@keyframes loaderPointAnimation {
		0% {
			background-color: #000000;
			box-shadow: 0 0 50px #000000;
		}

		16% {
			background-color: #000000;
			box-shadow: 0 0 50px #000000;
		}

		32% {
			background-color: #000000;
			box-shadow: 0 0 50px #000000;
		}

		48% {
			background-color: #000000;
			box-shadow: 0 0 50px #000000;
		}

		70% {
			background-color: #000000;
			box-shadow: 0 0 50px #000000;
		}

		100% {
			background-color: #FF7A00;
			box-shadow: 0px 0px 10px 5px #FF7A00;
		}
	}

	.loader-container .BigArrow {
		position: absolute;
		right: 20px;
		width: 48px;
		height: 48px;
		top: 20px;
	}
}

@media only screen and (min-width: 280px) and (max-width: 359px) {
	.loader {
		display: flex;
		justify-content: center;
		position: absolute;
		background-color: transparent;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		animation: loaderAnimation 5s ease-in-out;
	}

	@keyframes loaderAnimation {
		0% {
			top: 0%;
		}

		50% {
			top: 70%;
		}

		55% {
			top: 70%;
		}

		100% {
			top: 0%;
		}
	}

	.loader h1 {
		top: -150px;
		position: relative;
		text-align: center;
		display: flex;
		justify-content: center;
		font-size: 50px !important;
		bottom: 0;
	}

	.loader h1:after {
		content: '';
		display: block;
		top: -10px;
		right: 0;
		width: 16px;
		height: 16px;
		background-color: #FF7A00;
		box-shadow: 0px 0px 10px 5px #FF7A00;
		;
		border-radius: 50%;
		animation: loaderPointAnimation 3.5s;
		/* left: 62%; */
		/* top: 0%; */
		position: absolute;
	}

	@keyframes loaderPointAnimation {
		0% {
			background-color: #000000;
			box-shadow: 0 0 50px #000000;
		}

		16% {
			background-color: #000000;
			box-shadow: 0 0 50px #000000;
		}

		32% {
			background-color: #000000;
			box-shadow: 0 0 50px #000000;
		}

		48% {
			background-color: #000000;
			box-shadow: 0 0 50px #000000;
		}

		70% {
			background-color: #000000;
			box-shadow: 0 0 50px #000000;
		}

		100% {
			background-color: #FF7A00;
			box-shadow: 0px 0px 10px 5px #FF7A00;
		}
	}

	.loader-container .BigArrow {
		position: absolute;
		right: 20px;
		width: 48px;
		height: 48px;
		top: 20px;
	}
}