.navbar {
  height: 80px;
  background-color: transparent !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px !important;
  position: fixed;
  position: sticky !important;
  z-index: 999;
  top: 20px;
  width: 100%;
  transition: 0.5s ease-in;
  transform: translateX(-10px);
}

.navbar-bg {
  border-radius: 40px;
  background-color: #060606;
  box-shadow: rgb(245 245 245 / 0%) 0px 0.71133px 0.71133px 0px,
    rgb(183 183 183 / 1%) 0px 1.93715px 1.93715px 0px,
    rgb(193 193 193 / 2%) 1px 4px 4.25329px 0px,
    rgb(192 192 192 / 3%) 0px 2px 9.44132px 0px,
    rgb(161 161 161 / 8%) 1px -3px 24px 0px;
  width: fit-content;
  margin: auto;
  padding: 0px 30px !important;
  top: 20px;
  transition: 0.5s ease-in;
  transform: translateX(10px);
}

.navbar-container {
  margin: 0 auto;
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80%;
  width: 100%;
  transition: 0.5s ease-in;
  overflow: visible;
  position: relative;
}

.navChange {
  height: 80px;
  border-radius: 50px;
  background-color: #0f0f0f;
  box-shadow: rgb(245 245 245 / 0%) 0px 0.71133px 0.71133px 0px,
    rgb(183 183 183 / 1%) 0px 1.93715px 1.93715px 0px,
    rgb(193 193 193 / 2%) 1px 4px 4.25329px 0px,
    rgb(192 192 192 / 3%) 0px 2px 9.44132px 0px,
    rgb(161 161 161 / 8%) 1px -3px 24px 0px;
}

.logo {
  overflow: visible;
  padding: 0;
  position: relative;
  width: min-content;
}

.black .navbar_brand svg.logo_icon {
  fill: #060606;
}

.navbar_brand svg.logo_icon {
  fill: #f1f1f1;
}

.logoChange {
  fill: #f1f1f1 !important;
}

.menu_icon {
  display: none;
}

.nav_email {
  display: none;
}

.menu_icon .navbar_brand img.Hamburger_icon {
  stroke: #060606;
}
/* nav - middle */
.menu_item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  padding: 0;
  position: relative;
}

.menu_item ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin-bottom: 0px;
  align-items: center;
}

.menu_item ul li:not(:last-child) {
  margin-right: 8px;
}

.black .menu_item ul li a {
  font-weight: 400;
  color: #4b4b4b;
  text-decoration: none;
  border-radius: 20px;
  padding: 10px 20px;
  border: 1px solid #787879;
}
.changeMenu {
  color: #ababab !important;
  border: 1px solid #787879 !important;
}
.menu_item ul li a {
  font-weight: 400;
  color: #ababab;
  text-decoration: none;
  border-radius: 20px;
  padding: 10px 20px;
  border: 1px solid #787879;
}

.black .menu_item ul li a:hover {
  color: #060606;
  border: 1px solid #060606;
}
.changeMenu:hover {
  color: #f1f1f1 !important;
  border: 1px solid #f1f1f1 !important;
}
.menu_item ul li a:hover {
  color: #f1f1f1;
  border: 1px solid #f1f1f1;
}

.black .menu_item ul a.active {
  position: relative;
  border: 1px solid #060606;
  padding-left: 30px;
  color: #060606;
}
.changeMenu.active {
  color: #f1f1f1 !important;
  border: 1px solid #f1f1f1 !important;
}
.menu_item ul a.active {
  position: relative;
  border: 1px solid #f1f1f1;
  padding-left: 30px;
  color: #f1f1f1;
}

.menu_item ul a.active::after {
  content: "";
  position: absolute;
  top: 14px;
  left: 14px;
  width: 8px;
  height: 8px;
  background-color: #ff7a00;
  border-radius: 50%;
}

hr {
  height: 40px !important;
  border-left: 1px solid #404040 !important;
  border-top: none !important;
  margin: 20px !important;
  opacity: 1 !important;
}

.black a.navbar_button {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 50px;
  transition: all 0.5s ease-out;
  text-decoration: none;
  display: flex;
  gap: 5px;
  background-color: #060606;
  color: #f1f1f1;
  font-weight: 400;
}
.changeCollaborate {
  background-color: #f1f1f1 !important;
  color: #060606 !important;
}
a.navbar_button:hover .changeCollaborate {
  background-color: #f1f1f1 !important;
  color: #060606 !important;
  font-weight: 500;
}

.lets,
.navbar_button {
  display: inline-block;
}
a.navbar_button {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.5s ease-out;
  display: flex;
  gap: 5px;
  background-color: #f1f1f1;
  color: #060606;
  font-weight: 400;
  letter-spacing: 0.18px;
}
a.navbar_button:hover {
  font-weight: 500;
  letter-spacing: 0;
}
.black a.navbar_button:hover {
  letter-spacing: 0;
  font-weight: 500;
}

.black a.navbar_button .btn_arrow .icon_svg {
  transition: 0.1s ease-in;
  stroke: #f1f1f1;
}
.arrowChange {
  stroke: #060606 !important;
}
a.navbar_button .btn_arrow .icon_svg {
  transition: 0.1s ease-in;
  stroke: #060606;
}

a.navbar_button:hover .btn_arrow .icon_svg {
  transform: rotate(45deg);
}

@media only screen and (min-width: 894px) and (max-width: 992px) {

  .navbar-container {
    padding: 0px 30px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 893px) {

  .navbar-container {
    padding: 0px 30px;
  }
  .menu_item ul li:not(:last-child) {
    margin-right: 5px;
}
.menu_item ul {
  padding-left: 0;
}
}


@media (max-width: 768px) {
  .navbar {
    padding: 0px 15px;
  }

  .navbar-container {
    padding: 0px 24px;
  }

  .menu_icon {
    display: block;
    cursor: pointer;
    z-index: 999;
  }

  .navChange {
    height: 65px;
  }

  .black .menu_icon .navbar_brand .Hamburger_icon {
    stroke: #060606;
  }

  .change_Hamburger {
    stroke: #f1f1f1 !important;
  }

  .changeMenu:hover {
    border: none !important;
  }

  .menu_icon .navbar_brand .Hamburger_icon {
    stroke: #f1f1f1;
  }

  .menu_item {
    display: flex;
    position: fixed;
    left: 0;
    top: -110%;
    padding: 80px 0 30px;
    background-color: #060606;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(9px);
    width: 100%;
    height: 600px;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    visibility: hidden;
    z-index: 2;
  }
  .menu_item.active {
    visibility: visible;
    top: 0%;
    transition: all 0.5s ease-in-out;
    text-align: center;
  }

  .menu_item ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
    top: 100px;
    position: absolute;
    left: 0;
    flex-direction: column;
    width: 100%;
    padding: 0px 16px;
  }

  .menu_item ul li {
    margin: 0;
    width: 100%;
    left: 0;
    display: inline-block;
    justify-content: flex-start;
    display: flex;
    border-bottom: 1px solid #404040;
  }

  .menu_item ul li:last-child {
    border-bottom: none;
  }

  .menu_item ul li a {
    border: none;
    border-radius: 0px;
    padding: 24px 20px;
    font-size: 16px;
  }

  .menu_item ul li a:hover {
    border: none;
  }
  .menu_item ul li a.active {
    border: none;
    border-radius: 0px;
    padding-left: 20px;
  }

  .menu_item ul a.active::after {
    left: 0px;
    top: 32px;
  }

  .changeMenu {
    color: #ababab !important;
    border: none !important;
  }

  .changeMenu.active {
    color: #f1f1f1 !important;
    border: none !important;
  }
  .lets {
    position: absolute;
    left: 0;
    top: 400px;
    padding: 10px 16px;
  }

  .nav_email a {
    font-size: 31px;
    color: #787879 !important;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
  }

  .menu_item .nav_email {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    padding: 48px 16px;
    bottom: 0px;
  }
  .menu_item ul li:not(:last-child) {
    margin-right: 0px;
  }

  hr {
    display: none;
  }

  .black .menu_item ul li a {
    border: none;
    border-radius: 0px;
    padding: 24px 20px;
    font-size: 16px;
  }

  .black .menu_item ul li a {
    color: #ababab;
    border: none;
  }
  .black .menu_item ul li a:hover {
    color: #f1f1f1;
    border: none;
  }
  .black .menu_item ul a.active {
    border: none;
    color: #f1f1f1;
    padding-left: 20px;
  }
  .black .menu_item ul a.active::after {
    left: 0px;
    top: 32px;
  }
  .black a.navbar_button {
    background-color: #f1f1f1;
    color: #060606;
    font-weight: 400;
    letter-spacing: 0.18px;
  }
  .black a.navbar_button:hover {
    color: #060606;
    font-weight: 500;
    letter-spacing: 0;
  }
  .black a.navbar_button .btn_arrow .icon_svg {
    transition: 0.1s ease-in;
    stroke: #060606;
  }

  .logoChange {
    width: 30px;
    height: 30px;
    transition: 0.2s ease-in-out;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .logo_icon {
    width: 30px;
    height: 30px;
  }
  .logoChange {
    width: 30px;
    height: 30px;
    transition: 0.2s ease-in-out;
  }
  .menu_item ul {
    top: 60px;
  }
  .navChange {
    height: 56px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 540px) {
  .navbar {
    top: 0;
  }
  .navbar-container {
    padding: 0px 0px;
  }
  .logo_icon {
    width: 30px;
    height: 30px;
  }
  .logoChange {
    width: 24px;
    height: 24px;
    transition: 0.5s ease-in-out;
  }

  .menu_item ul {
    top: 60px;
  }

  .navbar-container {
    max-width: 100% !important;
    transition: 0.5s ease-in-out;
  }
  .navChange {
    max-width: 100% !important;
    padding: 0px 24px;
    height: 56px;
  }
}

@media only screen and (min-width: 280px) and (max-width: 359px) {
  .navbar {
    top: 0;
  }
  .navbar-container {
    padding: 0px 0px;
  }
  .logo_icon {
    width: 30px;
    height: 30px;
  }
  .logoChange {
    width: 24px;
    height: 24px;
    transition: 0.5s ease-in-out;
  }

  .navbar-container {
    max-width: 100% !important;
    transition: 0.5s ease-in-out;
  }
  .navChange {
    max-width: 100% !important;
  }
  .navChange {
    padding: 0px 20px;
    height: 56px;
  }
  .nav_email a {
    font-size: 23px;
  }
}
