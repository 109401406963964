.bg-color {
  background: #dee3e9;
  color: #060606;
}

.main_heading {
  padding-top: 160px;
  color: #060606 !important;
  font-size: 163px !important;
  text-align: center;
  font-weight: 500 !important;
  line-height: 120%;
  padding-bottom: 80px !important;
}

.h1_img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* Align items at the top */
  height: 100%;
  /* Set a height to the container */
}

.h1 {
  background: -webkit-linear-gradient(top,
      #000000 60%,
      #000000 50%,
      #ffffff 50%,
      #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  top: -5%;
  /* Position the element at the top */
  left: 50%;
  /* Position the element at the center horizontally */
  transform: translate(-50%, -50%);
  /* Center the element precisely */
  font-size: 163px !important;
  /* Use viewport width units for font size */
  font-weight: 500 !important;
  line-height: 120%;
}

.work_wrapper {
  padding-top: 140px;
  padding-bottom: 104px;
}

.work_wrapper .col-lg-4 {
  padding: 0 8px;
}

.work_div {
  height: 467px;
  position: relative;
  margin-bottom: 16px;
}

.work_div div {
  color: #f1f1f1;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}

.work_div span {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #ababab;
}

.work_div.img_1 {
  background-image: url("../../assets/images/works_img/img_1.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.work_div.img_1 img {
  width: 85%;
}

.work_div.img_2 {
  background-image: url("../../assets/images/works_img/img_2.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.work_div.img_2 img {
  width: 290px;
}

.div_position {
  position: absolute;
  top: 5%;
  left: 5%;
}

.div_position h5 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 18px;
}

.work_div.img_3 {
  background-image: url("../../assets/images/works_img/img_3.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.work_div.img_3 img {
  width: 330.988px;
}

.work_div.img_4 {
  background-image: url("../../assets/images/works_img/img_4.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.work_div.img_5 {
  background-image: url("../../assets/images/works_img/img_5.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.work_div.img_5 img {
  width: 356px;
  height: 279px;
}

.work_div.img_6 {
  background-image: url("../../assets/images/works_img/img_6.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.work_div.img_6 img {
  width: 339px;
  height: 213.492px;
}

.work_div.img_7 {
  background-image: url("../../assets/images/works_img/img_3.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.work_div.img_8 {
  background-image: url("../../assets/images/works_img/img_8.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.work_div.img_9 {
  background-image: url("../../assets/images/works_img/img_1.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.work_div img {
  width: auto;
  height: auto;
  transition: transform 1s;
}

.work_div:hover img {
  transform: scale(1.1);
  overflow: hidden;
}

/* --------responsive---- */
@media only screen and (min-width: 993px) and (max-width: 1280px) {
  .h1 {
    top: -3.1%;
  }
}

@media (max-width: 992px) {
  .main_heading {
    font-size: 140px !important;
  }

  .work_wrapper {
    padding-top: 120px;
    padding-bottom: 190px;
  }

  .h1 {
    font-size: 140px !important;
  }

  .work_wrapper .col-lg-4 {
    padding: 0 7.5px;
  }

  .main_heading {
    padding-bottom: 70px !important;
  }

  .work_div.img_2 img {
    width: 265px;
    height: 165px;
  }

  .work_div.img_3 img {
    width: 280px;
    height: 180px;
  }

  .work_div.img_5 img {
    width: 270px;
    height: 220px;
  }

  .work_div.img_6 img {
    width: 280px;
    height: 180px;
  }
  .work_div.img_7 img {
    width: 200px;
    height: 230px;
  }
  .work_div.img_8 img {
    width: 280px;
    height: 180px;
  }
  .work_div.img_9 img {
    width: 280px;
    height: 180px;
  }

  .work_div {
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .h1 {
    font-size: 120px !important;
  }

  .work_wrapper .col-md-6 {
    padding: 0 7px;
  }

  .main_heading {
    padding-bottom: 50px !important;
  }

  .work_wrapper {
    padding-top: 100px;
    padding-bottom: 75px;
  }

  .work_div {
    margin-bottom: 14px;
  }

  .work_div.img_1 img {
    width: 280px;
    height: 390px;
    padding: 130px 0 60px 0;
  }
  .work_div.img_2 img {
    width: 280px;
    height: 390px;
    padding: 150px 0 60px 0;
  }
  .work_div.img_3 img {
    width: 280px;
    height: 390px;
    padding: 130px 0 60px 0;
  }
  .work_div.img_6 img {
    width: 280px;
    height: 390px;
    padding: 130px 0 60px 0;
  }
  .work_div.img_7 img {
    width: 250px;
    height: 390px;
    padding: 130px 0 60px 0;
  }
  .work_div.img_8 img {
    width: 280px;
    height: 390px;
    padding: 140px 0 60px 0;
  }
  .work_div.img_9 img {
    width: 280px;
    height: 390px;
    padding: 140px 0 60px 0;
  }
}

@media (max-width: 576px) {
  .main_heading {
    font-size: 80px !important;
    padding-bottom: 50px !important;
  }

  .h1 {
    font-size: 82px !important;
  }

  .work_wrapper {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .work_div.img_1 img {
    width: 400px;
    height: 390px;
    padding: 100px 0 60px 0;
  }

  .work_div.img_2 img {
    width: 320px;
    height: 190px;
    padding: 100px 0 60px 0;
  }

  .work_wrapper .col-sm-12 {
    padding: 0;
  }

  .work_div.img_3 img {
    width: 350px;
    padding: 100px 0 60px 0;
  }

  .work_div.img_5 img {
    width: 350px;
    height: 250px;
  }

  .work_div.img_6 img {
    width: 350px;
    height: 200px;
    padding: 100px 0 60px 0;
  }
  .work_div.img_7 img {
    width: 350px;
    height: 200px;
    padding: 100px 0 60px 0;
  }
  .work_div.img_8 img {
    width: 350px;
    height: 200px;
    padding: 100px 0 60px 0;
  }
  .work_div.img_9 img {
    width: 350px;
    height: 200px;
    padding: 100px 0 60px 0;
  }

  .work_div {
    height: 440px;
    margin: 0 10px 13px 10px;
  }
}

@media (max-width: 360px) {
  .main_heading {
    font-size: 48px !important;
    padding-bottom: 23px !important;
  }

  .h1 {
    font-size: 48px !important;
  }

  .work_wrapper {
    padding-top: 60px;
    padding-bottom: 46px;
  }

  .work_wrapper .col-sm-12 {
    padding: 0;
    display: flex;
    justify-content: center;
  }

  .work_div.img_1 img {
    padding: 100px 0 60px 0;
    width: 260px;
  }

  .work_div.img_2 img {
    padding: 100px 0 60px 0;
    width: 291px;
    /* height: 183.263px; */
  }

  .work_div.img_3 img {
    padding: 100px 0 60px 0;
    width: 291px;
    height: 183.263px;
  }

  .work_div.img_5 img {
    padding: 100px 0 60px 0;
    width: 300px;
    height: 237px;
  }

  .work_div.img_6 img {
    padding: 120px 0 65px 0;
    width: 280px;
    height: 188.302px;
  }
  .work_div.img_7 img {
    padding: 120px 0 65px 0;
    width: 250px;
    height: 188.302px;
  }
  .work_div.img_8 img {
    padding: 120px 0 65px 0;
    width: 280px;
    height: 188.302px;
  }
  .work_div.img_9 img {
    padding: 120px 0 65px 0;
    width: 280px;
    height: 188.302px;
  }

  .work_div {
    width: 328px;
    height: 407px;
    margin-bottom: 12px;
  }
}