@import "./assets/fonts/general-sans/general-sans.css";


:root {
  /* --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
  --bs-body-bg: #060606;
  --bs-body-color: ;
  --bs-body-line-height: 1.5; */
  /* --bs-body-font-family: var(--bs-font-sans-serif); */
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  /* --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
}

* {
  margin: 0;
  padding: 0;
  font-family: 'GeneralSans-Variable';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: 'GeneralSans-Variable';
}

body {
 background-color: #060606 !important;
 color: #f1f1f1 !important;
}

@media (max-width: 360px) {
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

@media only screen and (min-width: 360px) and (max-width: 575px) {
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}










  
