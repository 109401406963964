.bg_colour {
  background-color: #ffffff;
  color: #060606;
}

.main {
  padding-top: 160px;
  padding-bottom: 140px;
  color: #060606 !important;
  font-size: 163px !important;
  text-align: left;
  font-weight: 500 !important;
  margin-bottom: 0;
}

.joinus_img_div {
  padding: 0 8px !important;
}

.joinus_img {
  width: 100%;
  height: 420px !important;
}

.joinus_wrapper {
  padding-top: 140px;
  color: #060606 !important;
  text-align: center;
  word-wrap: break-word;
  font-size: 72px !important;
  line-height: 120% !important;
  margin-bottom: 0;
}

.hr {
  margin: 80px 0;
}

.joinus_btn .btn_arrow .icon_svg {
  transition: 0.5s ease-in;
  stroke: #000000;
}

.joinus_btn {
  background-color: transparent;
  border: 1px solid #000000;
  border-radius: 50px;
  padding: 0px 20px;
  color: #000000;
  gap: 8px;
  transition: all 0.5s ease-out;
  background: linear-gradient(to right, #000000 50%, #ffffff 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.joinus_btn:hover,
.joinus_btn:focus {
  background-color: transparent;
  color: #ffffff;
  background-position: left bottom;
}

.joinus_btn:hover .icon_svg {
  stroke: #ffffff;
}

.joinus_btn:hover .btn_arrow {
  transform: rotate(45deg);
}

.main_joinus {
  display: flex;
}

.joinus_wrapper1 {
  font-weight: 500;
  color: #060606;
  font-size: 48px !important;
}

.designer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.designerr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.designer_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.designerr_div {
  padding: 13px 0;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.joinus_wrapper2 {
  color: #060606;
  font-size: 24px !important;
}

.joinus_wrapper2 {
  margin-bottom: 12px !important;
}

.career_exp {
  display: flex;
  gap: 10px;
  align-items: center;
}

.career_exp p {
  margin-bottom: 0;
  font-size: 18px;
  color: #4b4b4b;
  letter-spacing: -0.36px;
}

.career_exp span {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #060606;
}

.hr_1 {
  border-bottom: 1px solid #cbcaca;
  margin: 48px 0 35px;
}
.joinus_last{
  margin-bottom: 140px;
}

/* -------responsive---- */

@media (max-width: 992px) {
  .main {
    padding-bottom: 125px;
  }

  .joinus_wrapper {
    font-size: 59px !important;
    letter-spacing: -0.48px;
    padding-top: 95px;
  }
  .joinus_img_div {
    padding: 0 7px !important;
  }
  .joinus_img {
    height: 324px !important;
  }

  .hr {
    margin: 70px 0;
  }

  .joinus_wrapper1 {
    font-size: 40px !important;
  }

  .joinus_wrapper2 {
    font-size: 22px !important;
  }

  .career_exp p {
    font-size: 16px !important;
  }
  .joinus_last{
    margin-bottom: 120px;
  }
}

@media (max-width: 768px) {
  .main {
    font-size: 123px !important;
    padding-bottom: 110px;
  }

  .joinus_wrapper {
    font-size: 44px !important;
    padding-top: 80px;
    letter-spacing: -0.48px;
  }

  .hr {
    margin: 60px 0;
  }

  .joinus_img_div {
    padding: 0 7px !important;
  }
  .joinus_img {
    height: 270px !important;
  }

  .joinus_wrapper1 {
    font-size: 35px !important;
  }

  .joinus_wrapper2 {
    font-size: 21px !important;
  }

  .career_exp p {
    font-size: 15px !important;
  }
  .joinus_last{
    margin-bottom: 100px;
  }
}

@media (max-width: 576px) {
  .main {
    font-size: 90px !important;
    padding-bottom: 95px;
  }

  .joinus_wrapper {
    font-size: 33px !important;
    padding-top: 65px;
    letter-spacing: -0.48px;
  }

  .hr {
    /* border-bottom: 1px solid #060606; */
    margin: 50px 0;
  }

  .joinus_wrapper1 {
    font-size: 30px !important;
    margin: 0 !important;
  }

  .joinus_wrapper2 {
    font-size: 20px !important;
  }

  .career_exp p {
    font-size: 15px !important;
  }

  .main_joinus {
    display: inline-block;
  }
  .joinus_img_div {
    padding: 0 6px !important;
  }
  .joinus_img {
    height: 194px !important;
  }
  .designer,
  .designerr {
    display: contents;
  }

  .designerr_div {
    display: inline-block;
    padding: 0;
  }

  .gap_div {
    margin: 30px 0 18px 0;
  }
  .joinus_last{
    margin-bottom: 80px;
  }
}

@media (max-width: 360px) {
  .main {
    font-size: 48px !important;
    padding-top: 160px;
    font-weight: 500 !important;
    padding-bottom: 80px;
  }
  .hr {
    /* border-bottom: 1px solid #060606; */
    margin: 40px 0;
  }
  .hr_1 {
    margin: 40px 0;
  }
  .joinus_wrapper {
    font-size: 24px !important;
    padding-top: 40px;
    letter-spacing: -0.48px;
  }
  .joinus_img_div {
    padding: 0 6px !important;
  }
  .joinus_img {
    height: 119px !important;
  }
  .joinus_wrapper1 {
    font-size: 24px !important;
    margin: 0 !important;
  }

  .joinus_wrapper2 {
    font-size: 18px !important;
    margin-bottom: 8px !important;
    font-weight: 400 !important;
  }

  .career_exp p {
    font-size: 14px !important;
  }

  .main_joinus {
    display: inline-block;
  }

  .designer,
  .designerr {
    display: contents;
  }

  .designerr_div {
    display: inline-block;
    padding: 0 !important;
  }

  .gap_div {
    margin: 32px 0 20px 0;
  }
  .joinus_last{
    margin-bottom: 60px;
  }
}
