.video_wrapper {
  position: relative;
}

video {
  width: 100%;
  position: relative;
}
.video_img {
  width: -webkit-fill-available;
}

.play_btn {
  position: absolute;
  width: 140px;
  height: 140px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #ffd542;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play_btn img {
  position: absolute;
}


.button_text_up {
  position: absolute;
  top: 15%;
  left: 30%;
  transform: translate(-15%, -30%);
  color: #060606;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
}
/* .button_text_down {
  position: absolute;
  bottom: 9%;
  left: 30%;
  transform: translate(-19%, -30%);
  color: #060606;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
} */

/* .play_btn:hover {
  border: none;
} */

text {
  text-transform: uppercase;
  letter-spacing: 1px;
  animation: rotate 10s linear infinite;
  transform-origin: 50px 50px;
}

.play_btn svg {
  max-width: 125px;
  width: 125px;
  height: 125px;
}

@keyframes rotate {
  to {
     transform: rotate(360deg);
  }
}

.circle {
  transition: transform 5s cubic-bezier(0.45, 0 , 0.22, 1);
  transform-origin: 50px 50px;
}

svg:hover .circle {
  /* transform: scale(1) rotate(90deg); */
}

/*  Responsive CSS  */

@media (max-width: 992px) {
  
}

@media (max-width: 768px) {
  
}

@media (max-width: 576px) {
  .play_btn {
    width: 100px;
    height: 100px;
  } 
  .play_btn svg {
    max-width: 90px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 540px){
  .play_btn {
    width: 78px;
    height: 78px;
  } 
  .play_btn svg {
    max-width: 72px;
  }

.play_btn img {
  width: 16px;
  height: 16px;
}
  
}