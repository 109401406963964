#svg {
  top: -100px;
  left: 0;
  right: 0;
  position: absolute;
  height: 200px;
  width: 100%;
  z-index: 10;
  cursor: arrow;
}
.rope-line {
  position: relative;
  height: 1px;
  margin: 48px 0px;
}

.rope-line::before {
  content: "";
  display: block;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  height: 40px;
}

.rope-line svg {
  stroke : #F1F1F1
}

.black .rope-line svg {
  stroke : #060606
}


@media only screen and (min-width: 360px) and (max-width: 540px) {

  #svg {
    top: -100px;
    left: 0;
    right: 0;
    position: absolute;
    height: 200px;
    width: 100%;
    z-index: 10;
    cursor: arrow;
  }
  .rope-line {
    margin: 32px 0px;
  }
}