.expertise {
  display: flex;
  align-items: center;
  height: 800px;
}

.contact_main {
  width: 88%;
  padding-top: 160px;
}

.contact_hr {
  border-bottom: 1px solid #f1f1f1 !important;
  margin: 40px 0;
  width: 100%;  
}

.con_location {
  font-size: 16px;
  color: #ababab;
}
.con_location {
  font-size: 16px;
  color: #ababab;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.32px;
}

.con_address {
  color: #f1f1f1 !important;
  font-size: 16px;
  font-weight: 400;
  width: 55%;
  margin-bottom: 0;
}

.budget_select {
  width: auto !important;
  display: flex;
  padding: 10px 20px !important;
  align-items: flex-start;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--line-header-chips, #787879);
  background: var(#060606);
  color: #ababab;
  cursor: pointer;
}
.budget_select:hover {
  border: 1px solid #f1f1f1;
}
.budget_select_selected {
  cursor: pointer;
  color: #f1f1f1;
  border: 1px solid #f1f1f1;
}
.pin {
  gap: 8px;
  margin-bottom: 60px !important;
  cursor: pointer;
}

.pin p {
  margin-bottom: 0;
}

.pin img {
  height: 24px;
  width: 24px;
}

.attach {
  font-size: 20px;
  background-color: transparent;
  border: none;
  color: #f1f1f1;
}

.form_contact {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.form {
  width: 100%;
}

.form_wrapper {
  border: none;
  background-color: #060606;
  border-bottom: 1px solid var(--line-sparator, #404040);
  color: #f1f1f1 !important;
  width: 100%;
  font-size: 20px;
  height: 60px;
  display: flex;
  gap: 30px;
  outline: none;
  margin-bottom: 32px;
}

::placeholder {
  color: #4b4b4b;
  font-size: 20px !important;
}

.form_textarea_wrapper {
  border: none;
  background-color: #060606;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--line-sparator, #404040);
  color: #f1f1f1 !important;
  width: 100%;
  height: 108px;
  resize: none;
  font-size: 20px !important;
  display: flex;
  padding-top: 16px;
  outline: none;
}

.contact_heading {
  color: #4b4b4b;
  font-size: 48px !important;
  margin-bottom: 16px;
}

.coll_tag {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0px;
  width: 456px;
}

.coll_tag .coll_selector {
  background-color: #060606;
  border-radius: 30px;
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  color: #ababab;
  border: 1px solid #787879;
  cursor: pointer;
}

.coll_selector:hover {
  color: #ababab;
  border: 1px solid #f1f1f1;
 
}

.coll_tag .coll_selector_selected {
  cursor: pointer;
  color: #f1f1f1;
  border: 1px solid #f1f1f1;
}

.coll_tag .coll_selector .selected {
  border: 1px solid #f1f1f1;
}

.con_wrapper {
  color: #4b4b4b;
  font-size: 48px;
  padding-top: 160px;
  margin-bottom: 56px;
}

.budget {
  color: #4b4b4b !important;
  font-size: 20px;
  margin-bottom: 16px;
}

.budget_tag {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  color: #ababab;
  margin-bottom: 32px;
  padding-left: 0px;
  width: 420px;
}

.collaborate {
  margin-bottom: 140px;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
  font-size: 72px;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/*  Responsive CSS  */

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .contact_main {
    width: 100%;
    padding-top: 135px;
    margin-top: 0;
  }
  .con_address {
    width: 68%;
  }
  .contact_heading {
    font-size: 44px !important;
    margin-bottom: 15px !important;
  }
  .coll_selector {
    margin: 0 !important;
  }

  .form_wrapper {
    width: 100%;
  }
  .form_textarea_wrapper {
    width: 100%;
  }
  .contact_hr {
    width: 100%;
    margin: 55px 0;
  }
  .con_wrapper {
    padding-top: 135px;
    margin-top: 0;
    margin-bottom: 50px;
  }
  .pin {
    margin-bottom: 50px !important;
  }
  .attach {
    font-size: 20px;
  }
  .collaborate {
    margin-bottom: 120px;
  }
  .wave {
    font-size: 62px;
  }
}

@media (max-width: 768px) {
  .contact_main {
    width: 100%;
    padding-top: 110px;
    margin-top: 0;
  }
  .contact_hr {
    width: 100%;
    margin: 36px 0;
  }
  .con_location {
    font-size: 16px;
  }
  .con_address {
    font-size: 16x;
    width: 50%;
  }
  .con_wrapper {
    margin-top: 80px;
    margin-bottom: 34px !important;
    padding-top: 0;
  }
  .budget_tag {
    padding-left: 0;
    margin-bottom: 34px;
  }

  .form_wrapper {
    width: 100%;
    margin-bottom: 34px;
  }
  .form_textarea_wrapper {
    width: 100%;
    margin-bottom: 34px;
  }
  .attach {
    font-size: 19px;
  }
  .pin {
    margin-bottom: 50px !important;
  }
  .collaborate {
    margin-bottom: 100px;
  }
  .wave {
    font-size: 52px;
  }
}

@media only screen and (min-width: 361px) and (max-width: 576px) {
  .contact_main {
    width: 330px;
    padding-top: 82px;
    margin-top: 0;
  }
  .contact_hr {
    width: 100%;
    margin: 34px 0;
  }
  .con_location {
    font-size: 15px;
  }
  .con_address {
    font-size: 15x;
    width: 324px;
  }
  .con_wrapper {
    margin-top: 70px;
    margin-bottom: 29px !important;
    padding-top: 0;
  }
  .contact_heading {
    font-size: 30px !important;
  }
  .coll_tag {
    display: table-row-group;
    gap: 8px;
  }
  .coll_selector {
    padding: 8px 20px;
    font-size: 14px !important;
    margin: 0 7px 7px 0 !important;
  }
  .form_wrapper {
    width: 100%;
    margin-bottom: 27px !important;
    font-size: 17px;
  }
  .budget_tag {
    width: 90%;
    gap: 8px;
    padding-left: 0;
    margin-bottom: 24px;
  }
  .form_textarea_wrapper {
    width: 100%;
    margin-bottom: 24px;
  }
  .budget_select {
    padding: 8px 20px !important;
    gap: 8px;
  }
  .attach {
    font-size: 17px;
  }
  .pin {
    margin-bottom: 45px !important;
  }
  .collaborate {
    margin-bottom: 80px;
  }
  .wave {
    font-size: 42px;
  }
}

@media (max-width: 360px) {
  .contact_main {
    width: 328px;
    padding-top: 56px;
    margin-top: 0;
  }
  .contact_hr {
    width: 328px !important;
    margin: 32px 0;
  }
  .con_wrapper {
    margin-top: 60px;
    padding-top: 0;
    margin-bottom: 24px !important;
  }
  .con_location {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .con_address {
    font-size: 14px;
    width: 294px;
  }
  .coll_tag {
    display: inline-block;
    flex-wrap: wrap;
    width: 329px;
    gap: 8px;
  }
  .coll_selector {
    padding: 8px 20px !important;
    font-size: 14px !important;
    margin: 0 8px 8px 0 !important;
  }
  .contact_heading {
    font-size: 24px !important;
    margin-bottom: 12px !important;
  }
  .form_wrapper {
    width: 100%;
    margin-bottom: 24px !important;
    height: 56px !important;
  }
  .coll_btn {
    width: 159px;
    height: 40px;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    padding: 1px;
  }
  .form_contact {
    width: 100% !important;
  }
  .budget_tag {
    display: flex;
    flex-wrap: wrap;
    width: 330px !important;
    gap: 8px;
    padding-left: 0;
    margin-bottom: 24px;
  }
  .form_textarea_wrapper {
    width: 328px;
    margin-bottom: 24px;
    height: 90px;
  }
  .budget_select {
    padding: 8px 20px !important;
    gap: 8px;
  }
  .attach {
    font-size: 16px;
  }
  .pin {
    margin-bottom: 40px !important;
  }
  .form_contact button {
    padding: 0 22px;
  }
  ::placeholder {
    color: #4b4b4b;
    font-size: 16px !important;
  }
  .collaborate {
    margin-bottom: 60px;
  }
  .wave {
    font-size: 32px;
  }
}

@media only screen and (min-width: 280px) and (max-width: 359px) {
  .contact_main {
    width: 90%;
    padding-top: 50px;
    font-size: 30px !important;
  }
  .con_address {
    font-size: 12px;
    width: 100%;
    margin-bottom: 0;
  }
  .contact_hr {
    width: 100% !important;
    margin: 32px 0;
  }
  .coll_tag {
    display: inline-block;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
  }
  .form_wrapper {
    width: 100%;
    font-size: 15px;
  }
  ::placeholder {
    color: #4b4b4b;
    font-size: 15px !important;
  }
  .budget {
    font-size: 15px;
  }
  .form_textarea_wrapper {
    width: 100%;
  }
  .budget_tag {
    display: flex;
    flex-wrap: wrap;
    width: 250px !important;
    gap: 8px;
    padding-left: 0;
    margin-bottom: 24px;
  }
  .collaborate {
    margin-bottom: 40px;
  }
  .wave {
    font-size: 22px;
  }
}
